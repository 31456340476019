import { SigninResponse } from 'oidc-client-ts';
import { ssoEnv } from './sso-cookie';

/* istanbul ignore next */
const getBaseUrl = (env: ssoEnv) => {
    switch (env) {
        case 'prod':
            return '/___arte_tv/___subd-617069/api/sso/v4/admin/migrate';
        case 'preprod':
            return '/___arte_tv/___subd-6170692d70726570726f64/api/sso/v4/admin/migrate';
        case 'dev':
            return '/___arte_tv/___subd-6170692d646576/api/sso/v4/admin/migrate';
        default:
            return '/___arte_tv/___subd-617069/api/sso/v4/admin/migrate';
    }
};

type tokens = {
    anonymousToken: string;
    accessToken: string;
};
/* istanbul ignore next */
export const migrateAnonymous = async (env: ssoEnv, tokens: tokens) => {
    try {
        const response = await fetch(`${getBaseUrl(env)}/anonymous-data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                anonymousAccessToken: tokens.anonymousToken,
                userAccessToken: tokens.accessToken,
            }),
        });
        // should return a 202
        if (response.status !== 202) {
            console.error('Failed to migrate anonymous data:', response.status);
            return undefined;
        }
        return response;
    } catch (error) {
        console.error('Failed to migrate anonymous data:', error);
        return undefined;
    }
};
/* istanbul ignore next */
export const migrateLegacyToken = async (env: ssoEnv, token: string): Promise<SigninResponse | undefined> => {
    try {
        const response = await fetch(`${getBaseUrl(env)}/token-v3`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accessToken: token,
            }),
        });
        // should return a 200
        if (response.status !== 200) {
            console.error('Failed to migrate legacy token:', response.status);
            return undefined;
        }
        return response.json();
    } catch (error) {
        console.error('Failed to migrate anonymous data:', error);
        return undefined;
    }
};
