import { type ReactElement } from 'react';
import { ErrorFrontendPage } from '@artegeie/design-system/replay';
import { type Locale } from '@replay/i18n/types/locale';
import { errorToCode, ReplayError } from '@replay/types/Type_Error';

const LOCALE_DEFAULT: Locale = 'fr';

export function getErrorUrl(locale: Locale = LOCALE_DEFAULT): string {
    return `/___arte_tv/error/current/${locale}/500.html`;
}

const Page500 = ({ error }: { error: ReplayError }): ReactElement => {
    return <ErrorFrontendPage code={errorToCode(error)} />;
};

export default Page500;
