// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../provider/Context.res.mjs";

var $$default = (function(baseUrl){
  return {
    "fr": {
        "title":"Plan du site",
        "general":[
          {
            "label":"Accueil",
            "href": baseUrl + "/fr/",
            "id": "home"
          },
           {
            "label":"Guide TV",
            "href": baseUrl + "/fr/guide/",
            "id": "guide" 
          },
           {
            "label":"Direct",
            "href": baseUrl + "/fr/direct/",
            "id": "live" 
          },
           {
            "label":"ARTE Concert",
            "href": baseUrl + "/fr/arte-concert/",
            "id": "concert", 
          },
           {
            "label":"Afficher la recherche",
            "href": baseUrl + "/fr/search/",
            "id": "search"  
          }
        ],
      
        "categories": [
          {
            "label":"Documentaires et reportages",
            "href": baseUrl + "/fr/videos/documentaires-et-reportages",
            "id": "DOR",
          },
          {
            "label":"Cinéma",
            "href": baseUrl + "/fr/videos/cinema/",
            "id": "CIN",  
          },
          {
            "label":"Séries et fictions",
            "href": baseUrl + "/fr/videos/series-et-fictions/",
            "id": "SER",
          },
          {
            "label":"Info et société",
            "href": baseUrl + "/fr/videos/info-et-societe/",
            "id": "ACT",  
          },
          {
            "label":"Culture et pop",
            "href": baseUrl + "/fr/videos/culture-et-pop/",
            "id": "CPO",
          },
          {
            "label":"ARTE Concert",
            "href": baseUrl + "/fr/arte-concert/",
            "id": "ARS", 
          },
          {
            "label":"Sciences",
            "href": baseUrl + "/fr/videos/sciences/",
            "id": "SCI", 
          },
          {
            "label":"Voyages et découvertes",
            "href": baseUrl + "/fr/videos/voyages-et-decouvertes/",
            "id": "DEC",  
          },
          {
            "label":"Histoire",
            "href": baseUrl + "/fr/videos/histoire/",
            "id": "HIS",
          }
        ],  
        "menuNavigation":[
          {"id":"tvShows",
            "label":"Les émissions",
            "href": baseUrl + "/fr/videos/emissions/",
          },
          {"id":"allVideos",
            "label":"Toutes les vidéos",
            "href":baseUrl + "/fr/videos/plus-vues/",
          },
          {"id":"audiodescription",
            "label":"Audiodescription",
            "href":baseUrl + "/fr/videos/programmes-en-audiodescription/",
          },
          {"id":"invit",
            "label":"Invitations",
            "href":baseUrl + "/fr/profile/events/",
          },
          {"id":"threesixty",
            "label":"ARTE 360°",
            "href": "/___arte_tv/digitalproductions/categories/jeux-video",
          },
          {"id":"vodDvd",
            "label":"ARTE VOD/DVD",
            "href":"/___arte_tv/___subd-626f757469717565/",
          },
          {"id":"numProduct",
            "label":"Productions numériques",
            "href": "/___arte_tv/digitalproductions/",
          },
          {"id":"radio",
            "label":"ARTE Radio",
            "href":"https://arte.app.link/ARTE_Radio"
          },
        ],
    },
    "de": {
        "title": "Seitenübersicht",
        "general":[
          {
            "label":"Home",
            "href": baseUrl + "/de/",
            "id": "home"
          },
           {
            "label":"TV-Programm",
            "href": baseUrl + "/de/guide/",
            "id": "guide" 
          },
           {
            "label":"Live",
            "href": baseUrl + "/de/live/",
            "id": "live" 
          },
           {
            "label":"ARTE Concert",
            "href": baseUrl + "/de/arte-concert/",
            "id": "concert", 
          },
           {
            "label":"Die Suche anzeigen",
            "href": baseUrl + "/de/search/",
            "id": "search"  
          }
        ],  
        "categories": [
          {
            "label":"Dokus und Reportagen",
            "href": baseUrl + "de/videos/dokumentationen-und-reportagen/",
            "id": "DOR",
          },
          {
            "label":"Kino",
            "href": baseUrl + "/de/videos/kino/",
            "id": "CIN",  
          },
          {
            "label":"Fernsehfilme und Serien",
            "href": baseUrl + "/de/videos/fernsehfilme-und-serien/",
            "id": "SER",
          },
          {
            "label":"Aktuelles und Gesellschaft",
            "href": baseUrl + "/de/videos/aktuelles-und-gesellschaft/",
            "id": "ACT",  
          },
          {
            "label":"Kultur und Pop",
            "href": baseUrl + "/de/videos/kultur-und-pop/",
            "id": "CPO",
          },
          {
            "label":"ARTE Concert",
            "href": baseUrl + "/de/arte-concert/",
            "id": "ARS", 
          },
          {
            "label":"Wissenschaft",
            "href": baseUrl + "/de/videos/wissenschaft/",
            "id": "SCI", 
          },
          {
            "label":"Entdeckung der Welt",
            "href": baseUrl + "/de/videos/entdeckung-der-welt/",
            "id": "DEC",  
          },
          {
            "label":"Geschichte",
            "href": baseUrl + "/de/videos/geschichte/",
            "id": "HIS",
          }
        ],  
        "menuNavigation":[
          {"id":"tvShows",
            "label":"Alle Sendungen",
            "href": baseUrl + "/de/videos/sendungen/",
          },
          {"id":"allVideos",
            "label":"Alle Videos",
            "href":baseUrl + "/de/videos/meistgesehen/",
          },
          {"id":"audiodescription",
            "label":"Audiodescription",
            "href":baseUrl+"/de/videos/programme-mit-audiodeskription/",
          },
          {"id":"invit",
            "label":"Gästeliste",
            "href":baseUrl + "/de/profile/events/",
          },
          {"id":"threesixty",
            "label":"ARTE 360°",
            "href": "/___arte_tv/digitalproductions/de/categories/jeux-video/",
          },
          {"id":"magazin",
            "label":"ARTE Magazin",
            "href":"https://www.arte-magazin.de/",
          },
          {"id":"numProduct",
            "label":"Digitale Produktionen",
            "href": "/___arte_tv/digitalproductions/de/",
          },
        
        ],
    },
    "en": {
        "title": "Site map",
        "general":[
          {
            "label":"Home",
            "href": baseUrl + "/en/",
            "id": "home"
          },
         
           {
            "label":"ARTE Concert",
            "href": baseUrl + "/en/arte-concert/",
            "id": "concert", 
          },
           {
            "label":"Show the search bar",
            "href": baseUrl + "/en/search/",
            "id": "search"  
          }
        ],
        "categories": [
        
          {
            "label":"Politics and society",
            "href": baseUrl + "/en/videos/politics-and-society/",
            "id": "ACT",  
          },
          {
            "label":"Culture",
            "href": baseUrl + "/en/videos/culture/",
            "id": "CPO",
          },
          {
            "label":"ARTE Concert",
            "href": baseUrl + "/en/arte-concert/",
            "id": "ARS", 
          },
          {
            "label":"Sciences",
            "href": baseUrl + "/en/videos/sciences/",
            "id": "SCI", 
          },
          {
            "label":"Discovery",
            "href": baseUrl + "/en/videos/discovery/",
            "id": "DEC",  
          },
          {
            "label":"History",
            "href": baseUrl + "/en/videos/history/",
            "id": "HIS",
          }
        ],  
        "menuNavigation":[
          {"id":"tvShows",
            "label":"Magazine Shows",
            "href": baseUrl + "/en/videos/magazine-shows/",
          },
          {"id":"allVideos",
            "label":"All programmes",
            "href":baseUrl + "/en/videos/most-viewed/",
          },
        
          {"id":"numProduct",
            "label":"Digital Productions",
            "href": "/___arte_tv/digitalproductions/en/",
          },
          {"id":"about",
            "label":"About",
            "href": "/___arte_tv/sites/corporate/en/what-we-do/"
          },
        ],
    },
    "es": {
         "title": "Mapa web",
        "general":[
          {
            "label":"Home",
            "href": baseUrl + "/es/",
            "id": "home"
          },
         
           {
            "label":"ARTE Concert",
            "href": baseUrl + "/es/arte-concert/",
            "id": "concert", 
          },
           {
            "label":"Mostrar la búsqueda",
            "href": baseUrl + "/es/search/",
            "id": "search"  
          }
        ],    
        "categories": [
        
          {
            "label":"Política y sociedad",
            "href": baseUrl + "/es/videos/politica-y-sociedad/",
            "id": "ACT",  
          },
          {
            "label":"Cultura y pop",
            "href": baseUrl + "/es/videos/cultura-y-pop/",
            "id": "CPO",
          },
          {
            "label":"ARTE Concert",
            "href": baseUrl + "/es/arte-concert/",
            "id": "ARS", 
          },
          {
            "label":"Ciencias",
            "href": baseUrl + "/es/videos/ciencias/",
            "id": "SCI", 
          },
          {
            "label":"Saber y exploración",
            "href": baseUrl + "/es/videos/saber-y-exploracion/",
            "id": "DEC",  
          },
          {
            "label":"Historia",
            "href": baseUrl + "/es/videos/historia/",
            "id": "HIS",
          }
        ],  
        "menuNavigation":[
          {"id":"tvShows",
            "label":"Emisiones de A a Z",
            "href": baseUrl + "/es/videos/emisiones/",
          },
          {"id":"allVideos",
            "label":"Todos los vídeos",
            "href":baseUrl + "/es/videos/mas-vistos/",
          },
        
        
          {"id":"about",
            "label":"Quiénes somos",
            "href": "/___arte_tv/sites/corporate/es/que-hacemos/"
          },
        ],  
    },
    "pl": {
        "title":"Mapa strony",
        "general":[
          {
            "label":"Strona główna",
            "href": baseUrl + "/pl/",
            "id": "home"
          },
         
           {
            "label":"ARTE Concert",
            "href": baseUrl + "/pl/arte-concert/",
            "id": "concert", 
          },
           {
            "label":"Pokaż wyszukiwanie",
            "href": baseUrl + "/pl/search/",
            "id": "search"  
          }
        ],
        "categories": [
        
          {
            "label":"Polityka i społeczeństwo",
            "href": baseUrl + "/pl/videos/polityka-i-spoleczenstwo/",
            "id": "ACT",  
          },
          {
            "label":"Kultura",
            "href": baseUrl + "/pl/videos/kultura/",
            "id": "CPO",
          },
          {
            "label":"ARTE Concert",
            "href": baseUrl + "/pl/arte-concert/",
            "id": "ARS", 
          },
          {
            "label":"Nauka",
            "href": baseUrl + "/pl/videos/nauka/",
            "id": "SCI", 
          },
          {
            "label":"Odkrycia",
            "href": baseUrl + "/pl/videos/odkrycia/",
            "id": "DEC",  
          },
          {
            "label":"Historia",
            "href": baseUrl + "/pl/videos/historia/",
            "id": "HIS",
          }
        ],  
        "menuNavigation":[
          {"id":"tvShows",
            "label":"Magazyny od A do Z",
            "href": baseUrl + "/pl/videos/programy/",
          },
          {"id":"allVideos",
            "label":"Wszystkie programy",
            "href":baseUrl + "/pl/videos/najczesciej-ogladane/",
          },
        
        
          {"id":"about",
            "label":"O nas",
            "href": "/___arte_tv/sites/corporate/pl/nasza-dzialalnosc/"
          },
        ],
      },
    "it": {
        "title": "Site map",
        "general":[
          {
            "label":"Home",
            "href": baseUrl + "/it/",
            "id": "home"
          },
         
           {
            "label":"ARTE Concert",
            "href": baseUrl + "/it/arte-concert/",
            "id": "concert", 
          },
           {
            "label":"Mostra la ricerca",
            "href": baseUrl + "/it/search/",
            "id": "search"  
          }
        ],
        "categories": [
        
          {
            "label":"Politica e società",
            "href": baseUrl + "/it/videos/politica-e-societa/",
            "id": "ACT",  
          },
          {
            "label":"Cultura",
            "href": baseUrl + "/it/videos/cultura/",
            "id": "CPO",
          },
          {
            "label":"ARTE Concert",
            "href": baseUrl + "/it/arte-concert/",
            "id": "ARS", 
          },
          {
            "label":"Scienze",
            "href": baseUrl + "/it/videos/scienze/",
            "id": "SCI", 
          },
          {
            "label":"Viaggi e scoperte",
            "href": baseUrl + "/it/videos/viaggi-e-scoperte/",
            "id": "DEC",  
          },
          {
            "label":"Storia",
            "href": baseUrl + "/it/videos/storia/",
            "id": "HIS",
          }
        ],  
        "menuNavigation":[
          {"id":"tvShows",
            "label":"Transmissioni da A a Z",
            "href": baseUrl + "/pl/videos/programy/",
          },
          {"id":"allVideos",
            "label":"Tutti i video",
            "href":baseUrl + "/it/videos/piu-visti/",
          },
        
        
          {"id":"about",
            "label":"Chi siamo",
            "href": "/___arte_tv/sites/corporate/it/cosa-facciamo/"
          },
        ],
    }
  } });

function make() {
  var match = Context.Config.use();
  var baseUrl = match.baseUrl;
  var match$1 = Context.I18n.use();
  var data = React.useMemo((function () {
          return $$default(baseUrl);
        }), [baseUrl]);
  switch (match$1.locale) {
    case "Fr" :
        return data.fr;
    case "De" :
        return data.de;
    case "Pl" :
        return data.pl;
    case "It" :
        return data.it;
    case "En" :
        return data.en;
    case "Es" :
        return data.es;
    
  }
}

var UseSiteMapData = {
  make: make
};

export {
  $$default as default,
  UseSiteMapData ,
}
/* react Not a pure module */
