import { ssoEnv } from './sso-cookie';

/* istanbul ignore next */
const getBaseUrl = (env: ssoEnv) => {
    switch (env) {
        case 'prod':
            return '/___arte_tv/___subd-617069/api/sso/v3/me';
        case 'preprod':
            return '/___arte_tv/___subd-6170692d70726570726f64/api/sso/v3/me';
        case 'dev':
            return '/___arte_tv/___subd-6170692d646576/api/sso/v3/me';
        default:
            return '/___arte_tv/___subd-617069/api/sso/v3/me';
    }
};

/* istanbul ignore next */
const getMe = async (env: ssoEnv, token: string) => {
    const response = await fetch(getBaseUrl(env), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            client: 'web',
        },
    });
    return response;
};

/* istanbul ignore next */
export const pollMe = async (env: ssoEnv, token: string, maxRetries = 5, delay = 2000) => {
    let retries = 0;
    let response = await getMe(env, token);
    while (response.status !== 200 && retries < maxRetries) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        response = await getMe(env, token);
        retries++;
        delay += 2000;
    }
    return response;
};
