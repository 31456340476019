// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Atom_Image from "../../atom/Atom_Image.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Molecule_TopTeaser from "../../molecule/Molecule_TopTeaser.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Molecule_TopTeaser_Css from "../../molecule/Molecule_TopTeaser_Css.res.mjs";
import * as Particle_Background_Ds from "../../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function Organism_Error500(props) {
  var match = Context.Config.use();
  return JsxRuntime.jsxs(Atom_Column.make, {
              alignItems: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
              background: Caml_option.some(Particle_Background_Ds.Oops.background500(match.cdnUrl)),
              blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.Inside.make("None", "XL", "M", "M"))),
              children: [
                JsxRuntime.jsx(Atom_Image.ImageFromCdn.make, {
                      alt: props.imgAlt,
                      style: {
                        TAG: "FromCdn",
                        _0: "Error500"
                      }
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                      blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.Both.makeTop("L", "XL"))),
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(props.title),
                              style: Atom_Text_Ds.Oops._500Title
                            }),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(props.subTitle),
                              style: Atom_Text_Ds.Oops._500Subtitle
                            }),
                        JsxRuntime.jsx(Atom_Cta.Link.make, {
                              href: props.ctaHref,
                              label: props.cta,
                              style: Atom_Cta_Ds.backHome500
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.sInnerSpace)
                    })
              ],
              rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                        CssJs.paddingTop(CssJs.px(130)),
                        CssJs.backgroundSize("cover")
                      ]))
            });
}

var images = {
  TAG: "Resolution",
  _0: [
    {
      w: 480,
      h: 480,
      url: "/___arte_tv/___subd-7374617469632d63646e/static/design-system/errorjs/background-480x480.jpg"
    },
    {
      w: 840,
      h: 473,
      url: "/___arte_tv/___subd-7374617469632d63646e/static/design-system/errorjs/background-840x473.jpg"
    },
    {
      w: 1008,
      h: 567,
      url: "/___arte_tv/___subd-7374617469632d63646e/static/design-system/errorjs/background-1008x567.jpg"
    },
    {
      w: 1121,
      h: 632,
      url: "/___arte_tv/___subd-7374617469632d63646e/static/design-system/errorjs/background-1121x632.jpg"
    }
  ]
};

var wrapperHeight = Particle_Screen_Ds.make(undefined, undefined, CssJs.px(473), CssJs.px(632), undefined, "auto");

var wrapperBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("L"));

function make(param) {
  var match = Context.I18n.use();
  var match$1 = match.labels.error;
  return JsxRuntime.jsxs(Atom_Row.make, {
              blockSpace: Caml_option.some(wrapperBlockSpace),
              children: [
                JsxRuntime.jsx(Molecule_TopTeaser.$$Image.make, {
                      srcs: images
                    }),
                JsxRuntime.jsx(Atom_Cell.make, {
                      justifyContent: Caml_option.some(Molecule_TopTeaser_Css.Content.justifyContent),
                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                      blockSpace: Caml_option.some(Molecule_TopTeaser_Css.Content.blockSpace),
                      width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                      maxWidth: Caml_option.some(Molecule_TopTeaser_Css.Content.maxWidth),
                      height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
                      children: JsxRuntime.jsxs(Atom_Column.make, {
                            children: [
                              JsxRuntime.jsx(Atom_Text.make, {
                                    children: Caml_option.some(match$1.title500),
                                    kind: "h2",
                                    style: Atom_Text_Ds.Oops._500FrontendTitle
                                  }),
                              JsxRuntime.jsx(Atom_Text.make, {
                                    blockSpace: Caml_option.some(Molecule_TopTeaser_Css.Content.subtitleSpacer),
                                    children: Caml_option.some(match$1.errorCode + param.code),
                                    kind: "h3",
                                    style: Atom_Text_Ds.Oops._404HaikuSubtitle
                                  }),
                              JsxRuntime.jsx(Atom_Column.make, {
                                    alignItems: Caml_option.some(Molecule_TopTeaser_Css.alignItems),
                                    blockSpace: Caml_option.some(Molecule_TopTeaser_Css.DescriptionButtons.blockSpace),
                                    children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                                          href: match$1.buttonUrl500,
                                          label: match$1.buttonLabel500,
                                          style: Atom_Cta_Ds.Teaser.makeTeaserPlay(undefined, undefined, "TopTeaser")
                                        }),
                                    innerSpace: Caml_option.some(Molecule_TopTeaser_Css.ButtonsChildrenRow.innerSpace)
                                  })
                            ]
                          }),
                      rules: Caml_option.some(Molecule_TopTeaser_Css.Content.rules)
                    })
              ],
              height: Caml_option.some(wrapperHeight),
              id: "error-500",
              justifyContent: Caml_option.some(Molecule_TopTeaser_Css.Container.justifyContent),
              position: Caml_option.some(Particle_Screen_Constants.positionRelative),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            }, "error-500");
}

var Frontend = {
  images: images,
  wrapperHeight: wrapperHeight,
  wrapperBlockSpace: wrapperBlockSpace,
  make: make
};

var make$1 = Organism_Error500;

export {
  make$1 as make,
  Frontend ,
}
/* wrapperHeight Not a pure module */
