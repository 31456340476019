// Generated by ReScript, PLEASE EDIT WITH CARE


var $$default = (function (baseUrl) {
    return {
        
            Concert: {
                title: 'ARTE Concert',
                url: baseUrl + '/fr/arte-concert/',
                navigation: {
                    links: [
                        {
                            label: 'Explorer par genre',
                            href: baseUrl + '/fr/arte-concert/plus-recentes/ ',
                            id: 'concert-genres',
                            kind: 'other',
                        },
                        {
                            label: 'À venir',
                            href: baseUrl + '/fr/arte-concert/agenda/',
                            id: 'guide_concert',
                            kind: 'other',
                        },
                    ],
                },
                socialNetworks:{
                    facebook: 'https://www.facebook.com/ARTEConcert',
                    twitter: "https://twitter.com/ARTEconcertFR",
                    instagram: "https://www.instagram.com/arteconcertfr/",
                    youtube: "https://www.youtube.com/c/arteconcert",
                    tiktok: "https://www.tiktok.com/@arteconcertfr",
                },
                concertSelectGenre:{
                    title:{label:"Explorer par genre", href:baseUrl +"/fr/arte-concert/plus-recentes/"},
                    items:[
                        {
                            label:"Pop & Rock",
                            href:  baseUrl +"/fr/p/pop-rock/" 
                               
                        },
                        {
                            label:"Electro",
                            href:  baseUrl+"/fr/p/musiques-electroniques/" 
                        },
                        {
                            label:"Metal",
                            href:baseUrl +"/fr/p/metal/"
                        },
                        {
                            label:"Hip-hop",
                            href: baseUrl +"/fr/p/hip-hop/"
                        },
                        {
                            label:"Jazz",
                            href:baseUrl +"/fr/p/jazz/"
                        },
                        {
                            label:"Classique",
                            href:baseUrl +"/fr/p/classique/"
                        },
                        {
                            label:"Opéra",
                            href:baseUrl +"/fr/p/opera/"
                        },
                        {
                            label:"Baroque",
                            href:  baseUrl +"/fr/p/musique-baroque/"
                        },
                        {
                            label:"Arts de la scène",
                            href:baseUrl +"/fr/p/arts-de-la-scene/"
                        },
                        {
                            label:"World",
                            href:baseUrl +"/fr/p/world/"
                        },
                       
                    ]
                },
                guide: {label: 'À venir',
                            href: baseUrl + '/fr/arte-concert/agenda/'},
            },
            LogoNavigation: {
                label: '',
                title: 'Arte, accueil',
                href: baseUrl + '/fr/',
            },
            DesktopNavigation: {
                links: [
                    {
                        label: 'Guide TV',
                        href: baseUrl + '/fr/guide/',
                        id: 'tv_guide',
                    },
                    {
                        label: 'Direct',
                        href: baseUrl + '/fr/direct/',
                        id: 'live',
                    },
                    {
                        label: 'Bientôt en ligne',
                        href: baseUrl + '/fr/p/a-venir/',
                        id: 'upcoming',
                    },
                    {
                        label: 'ARTE Concert',
                        href: baseUrl + '/fr/arte-concert/',
                        id: 'arte_concert',
                    },
                ],
            },
            MenuNavigation: {
                mobileLinks: [
                    {
                        label: 'Accueil',
                        href: baseUrl + '/fr/',
                        id: 'home',
                    },
                    {
                        label: 'Guide TV',
                        href: baseUrl + '/fr/guide/',
                        id: 'tv_guide',
                    },
                    {
                        label: 'Direct',
                        href: baseUrl + '/fr/direct/',
                        id: 'live',
                    },
                    {
                        label: 'Bientôt en ligne',
                        href: baseUrl + '/fr/p/a-venir/',
                        id: 'upcoming',
                    },
                ],
                categories: [
                    {
                        label: 'Documentaires et reportages',
                        kind: 'internal',
                        href: baseUrl + '/fr/videos/documentaires-et-reportages/',
                        code: 'DOR',
                     
                    },
                    {
                        label: 'Cinéma',
                        kind: 'internal',
                        href: baseUrl + '/fr/videos/cinema/',
                        code: 'CIN',
                      
                      
                    },
                    {
                        label: 'Séries et fictions',
                        kind: 'internal',
                        href: baseUrl + '/fr/videos/series-et-fictions/',
                        code: 'SER',
                      
                      
                    },
                    {
                        label: 'Info et société',
                        kind: 'internal',
                        href: baseUrl + '/fr/videos/info-et-societe/',
                        code: 'ACT',
                      
                      
                    },
                    {
                        label: 'Culture et pop',
                        kind: 'internal',
                        href: baseUrl + '/fr/videos/culture-et-pop/',
                        code: 'CPO',
                      
                      
                    },
                    {
                        label: 'ARTE Concert',
                        kind: 'internal',
                        href: baseUrl + '/fr/arte-concert/',
                        code: 'ARS',
                      
                      
                    },
                    {
                        label: 'Sciences',
                        kind: 'internal',
                        href: baseUrl + '/fr/videos/sciences/',
                        code: 'SCI',
                      
                      
                    },
                    {
                        label: 'Voyages et découvertes',
                        kind: 'internal',
                        href: baseUrl + '/fr/videos/voyages-et-decouvertes/',
                        code: 'DEC',
                      
                      
                    },
                    {
                        label: 'Histoire',
                        kind: 'internal',
                        href: baseUrl + '/fr/videos/histoire/',
                        code: 'HIS',
                      
                      
                    },
                ],
                otherLinks: [
                    {
                        label: 'Les émissions',
                        href: baseUrl + '/fr/videos/emissions/',
                        code: null,
                        kind: 'internal',
                    },
                    {
                        label: 'Toutes les vidéos',
                        href: baseUrl + '/fr/videos/plus-vues/',
                        code: null,
                        kind: 'internal',
                    },
                    {
                        label: 'Versions accessibles des programmes',
                        href: baseUrl + '/fr/p/versions-accessibles-des-programmes/',
                        code: null,
                        kind: 'internal',
                    },
                    {
                        label: 'Jeux concours',
                        href: baseUrl + '/fr/profile/events/',
                        code: null,
                        kind: 'internal',
                    },
                     {
                        label: 'Assistant virtuel',
                        href: '/___arte_tv/___subd-63686174626f74/fr/',
                        code: null,
                        kind: 'external',
                    },
                    {
                        label: 'Les jeux vidéo',
                        href: '/___arte_tv/digitalproductions/categories/jeux-video/',
                        code: null,
                        kind: 'external',
                    },
                    {
                        label: 'ARTE VOD/DVD',
                        href: '/___arte_tv/___subd-626f757469717565/',
                        code: null,
                        kind: 'external',
                    },
                    {
                        label: 'Productions numériques',
                        href: '/___arte_tv/digitalproductions/',
                        code: null,
                        kind: 'external',
                    },
                    {
                        label: 'ARTE Radio',
                        href: 'https://arte.app.link/ARTE_Radio/',
                        kind: 'external',
                    },
                ],
            },        
    };
});

export {
  $$default as default,
}
/* No side effect */
