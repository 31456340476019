// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Util_Css from "../../util/Util_Css.res.mjs";
import * as Util_Dom from "../../util/Util_Dom.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Icon from "../atom/Atom_Icon.res.mjs";
import * as Atom_Logo from "../atom/Atom_Logo.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Atom_Image from "../atom/Atom_Image.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.res.mjs";
import * as Atom_Icon_Ds from "../atom/Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Border_Ds from "../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Atom_InstallMoblileApp from "../atom/Atom_InstallMoblileApp.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.make("XS", "XS", "XL", "None"), undefined, Particle_Spacer_Ds.make("XS", "XS", "S", "None"));

var blockSpaceRight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeRight("S"));

function Page_Short$Header(props) {
  return JsxRuntime.jsxs(Atom_Row.make, {
              blockSpace: Caml_option.some(blockSpace),
              children: [
                JsxRuntime.jsx(Atom_Cell.make, {
                      blockSpace: Caml_option.some(blockSpaceRight),
                      children: JsxRuntime.jsx(Atom_Logo.make, {
                            logo: {
                              NAME: "ArteVerticalLogo",
                              VAL: "ColoredBox"
                            }
                          })
                    }),
                JsxRuntime.jsxs(Atom_Row.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Cta.Link.make, {
                              href: "/",
                              label: "/___arte_tv",
                              style: Atom_Cta_Ds.$$short
                            }),
                        JsxRuntime.jsx(Atom_Cell.make, {
                              alignSelf: Caml_option.some(Particle_Screen_Constants.alignSelfCenter),
                              display: Caml_option.some(Util_Css.Display.untilL),
                              blockSpace: Caml_option.some(blockSpaceRight),
                              children: JsxRuntime.jsx(Atom_Text.make, {
                                    children: Caml_option.some(props.moreShortsText),
                                    style: Atom_Text_Ds.Shorts.header
                                  })
                            }),
                        JsxRuntime.jsx(Atom_InstallMoblileApp.make, {})
                      ]
                    })
              ],
              ref_: props.headerRef
            });
}

var Header = {
  blockSpace: blockSpace,
  blockSpaceRight: blockSpaceRight,
  make: Page_Short$Header
};

var blockSpace$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeLeft("XL"));

var blockSpaceImg = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.Outside.makeBottom("S"));

var gap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");

var maxWidth = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      NAME: "px",
      VAL: 213
    });

var border = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.qrCode);

function Page_Short$QrCode(props) {
  return JsxRuntime.jsxs(Atom_Column.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              blockSpace: Caml_option.some(blockSpace$1),
              children: [
                JsxRuntime.jsx(Atom_Cell.make, {
                      alignSelf: Caml_option.some(Particle_Screen_Constants.alignSelfCenter),
                      blockSpace: Caml_option.some(blockSpaceImg),
                      border: Caml_option.some(border),
                      children: JsxRuntime.jsx(Atom_Image.ImageFromCdn.make, {
                            alt: "QrCode",
                            style: {
                              TAG: "FromCdn",
                              _0: "QrCode"
                            }
                          })
                    }),
                JsxRuntime.jsxs(Atom_Row.make, {
                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                      children: [
                        JsxRuntime.jsx(Atom_Logo.make, {
                              logo: {
                                NAME: "ArteVerticalLogo",
                                VAL: "Shorts"
                              }
                            }),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: "Shorts",
                              kind: "h2",
                              style: Atom_Text_Ds.Shorts.title
                            }),
                        JsxRuntime.jsx(Atom_Icon.make, {
                              icon: Atom_Icon_Ds.shorts,
                              size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XL")
                            })
                      ],
                      gap: Caml_option.some(gap)
                    }),
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(props.shortsTitle),
                      maxWidth: Caml_option.some(maxWidth),
                      style: Atom_Text_Ds.Shorts.desc
                    })
              ],
              display: Caml_option.some(Util_Css.Display.fromL),
              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter)
            });
}

var QrCode = {
  blockSpace: blockSpace$1,
  blockSpaceImg: blockSpaceImg,
  gap: gap,
  maxWidth: maxWidth,
  border: border,
  make: Page_Short$QrCode
};

var blockSpace$2 = Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeRight("XL"), undefined, Particle_Spacer_Ds.makeNone());

var width = Particle_Screen_Ds.make(undefined, undefined, {
      NAME: "px",
      VAL: 288
    }, undefined, undefined, {
      NAME: "vw",
      VAL: 100
    });

function height(headerHeight) {
  return Particle_Screen_Ds.make(undefined, undefined, {
              NAME: "px",
              VAL: 515
            }, undefined, undefined, Css_Js_Core.Calc.$neg(CssJs.vh(100), CssJs.px(headerHeight)));
}

function Page_Short$Player(props) {
  var headerElement = Util_Dom.getDomRef(props.headerRef);
  var headerHeight = headerElement !== undefined ? Caml_option.valFromOption(headerElement).clientHeight : 0;
  return JsxRuntime.jsx(Atom_Column.make, {
              blockSpace: Caml_option.some(blockSpace$2),
              children: props.player,
              height: Caml_option.some(height(headerHeight)),
              position: Caml_option.some(Particle_Screen_Constants.positionRelative),
              width: Caml_option.some(width)
            });
}

var Player = {
  blockSpace: blockSpace$2,
  position: Particle_Screen_Constants.positionRelative,
  width: width,
  height: height,
  make: Page_Short$Player
};

var wrapperRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.flexDirection("column"),
      CssJs.justifyContent(CssJs.center)
    ]);

var maxScreenHeight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.vh(100));

function make(param) {
  var match = Context.I18n.use();
  var match$1 = match.labels.shorts;
  var headerRef = React.useRef(null);
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Page_Short$Header, {
                      moreShortsText: match$1.more_shorts,
                      headerRef: headerRef
                    }),
                JsxRuntime.jsx(Atom_Cell.make, {
                      height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
                      children: JsxRuntime.jsxs(Atom_Row.make, {
                            children: [
                              JsxRuntime.jsx(Page_Short$Player, {
                                    player: param.player,
                                    headerRef: headerRef
                                  }),
                              JsxRuntime.jsx(Page_Short$QrCode, {
                                    shortsTitle: match$1.title
                                  })
                            ],
                            justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter)
                          }),
                      rules: Caml_option.some(wrapperRules)
                    })
              ],
              height: Caml_option.some(maxScreenHeight)
            });
}

export {
  Header ,
  QrCode ,
  Player ,
  wrapperRules ,
  maxScreenHeight ,
  make ,
}
/* blockSpace Not a pure module */
