// Generated by ReScript, PLEASE EDIT WITH CARE


var $$default = (function (baseUrl) {
    return {

            Concert: {
                title: 'ARTE Concert',
                url: baseUrl + '/pl/arte-concert/',
                navigation: {
                    links: [
                        {
                            label: 'Szukaj według gatunku',
                            href: baseUrl + '/pl/arte-concert/najnowsze/ ',
                            id: 'concert-genres',
                            kind: 'other',
                        },
                    ],
                },
                socialNetworks:{
                    facebook: null,
                    twitter: null,
                    instagram: null,
                    youtube: null,
                    tiktok: null,
                },
                   concertSelectGenre:{
                    title:{label:"Wszystkie gatunki", href:baseUrl +"/pl/arte-concert/najnowsze/"},
                    items:[
                        {
                            label:"Pop i rock",
                            href:baseUrl +"/pl/p/pop-i-rock/"
                        },
                        {
                            label:"Electro",
                            href: baseUrl+"/pl/p/muzyka-elektroniczna/" 
                        },
                        {
                            label:"Metal",
                            href:baseUrl +"/pl/p/metal/"
                        },
                        {
                            label:"Hip-hop",
                            href:baseUrl +"/pl/p/hip-hop/"
                        },
                        {
                            label:"Jazz",
                            href:baseUrl +"/pl/p/jazz/"
                        },
                        {
                            label:"Muzyka klasyczna",
                            href:baseUrl +"/pl/p/muzyka-klasyczna/"
                        },
                        {
                            label:"Opera",
                            href:baseUrl +"/pl/p/opera/"
                        },
                        {
                            label:"Muzyka barokowa",
                            href:baseUrl +"/pl/p/muzyka-barokowa/"
                        },
                        {
                            label:"Występy sceniczne",
                            href:baseUrl +"/pl/p/wystepy-sceniczne/"
                        },
                        {
                            label:"Muzyka z całego świata",
                            href:baseUrl +"/pl/p/muzyka-z-calego-swiata/"
                        },
                       
                    ]
               
            },
               guide:null,
            },
            DesktopNavigation: {
                links: [
                    {
                        label: 'Wyszukiwanie',
                        href: baseUrl + '/pl/search/',
                        id: 'search',
                    },
                    {
                        label: 'O nas',
                        href: '/___arte_tv/sites/pl/corporate/nasza-dzialalnosc/?lang=pl',
                        id: 'about',
                    },
                ],
            },
            MenuNavigation: {
                mobileLinks: [
                    {
                        label: 'Strona główna',
                        href: baseUrl + '/pl/',
                    },
                 {
                        label: 'Wkrótce dostępne',
                        href: baseUrl + '/pl/p/wkrotce-dostepne/',
                        id: 'upcoming',
                    },
                    {
                        label: 'Wyszukiwanie',
                        href: baseUrl + '/pl/search/',
                        id: 'search',
                    },
                    {
                        label: 'O nas',
                        href: '/___arte_tv/sites/pl/corporate/nasza-dzialalnosc/?lang=pl',
                        id: 'about',
                    },
                ],
                categories: [
                    {
                        label: 'Polityka i społeczeństwo',
                        kind: 'internal',
                        href: baseUrl + '/pl/videos/polityka-i-spoleczenstwo/',
                        code: 'ACT',
                    },
                    {
                        label: 'Kino',
                        kind: 'internal',
                        href: baseUrl + '/pl/videos/kino/',
                        code: 'CIN',
                    },
                    {
                        label: 'Seriale',
                        kind: 'internal',
                        href: baseUrl + '/pl/videos/seriale-i-filmy-fabularne/',
                        code: 'SER',
                    },
                    {
                        label: 'Kultura',
                        kind: 'internal',
                        href: baseUrl + '/pl/videos/kultura/',
                        code: 'CPO',
                    },
                    {
                        label: 'ARTE Concert',
                        kind: 'internal',
                        href: baseUrl + '/pl/arte-concert/',
                        code: 'ARS',
                    },
                    {
                        label: 'Nauka',
                        kind: 'internal',
                        href: baseUrl + '/pl/videos/nauka/',
                        code: 'SCI',
                    },
                    {
                        label: 'Odkrycia',
                        kind: 'internal',
                        href: baseUrl + '/pl/videos/odkrycia/',
                        code: 'DEC',
                    },
                    {
                        label: 'Historia',
                        kind: 'internal',
                        href: baseUrl + '/pl/videos/historia/',
                        code: 'HIS',
                    },
                ],
                otherLinks: [
                    {
                        label: 'Magazyny',
                        href: baseUrl + '/pl/videos/magazyny/',
                        code: null,
                        kind: 'internal',
                    },
                    {
                        label: 'Gry wideo',
                        href: '/___arte_tv/digitalproductions/pl/categories/gry-wideo/',
                        code: null,
                        kind: 'external',
                    },
                    {
                        label: 'Wszystkie programy',
                        href: baseUrl + '/pl/videos/najczesciej-ogladane/',
                        code: null,
                        kind: 'internal',
                    },
                    {
                        label: 'Produkcje cyfrowe',
                        href: '/___arte_tv/digitalproductions/pl/',
                        code: null,
                        kind: 'external',
                    },
                ],
            },
            LogoNavigation: {
                label: 'po polsku',
                title: 'Arte po polsku',
                href: baseUrl + '/pl/',
                links: [
                    {
                        label: 'ARTE Concert',
                        id: 'arte_concert',
                        href: baseUrl + '/pl/arte-concert/',
                    },
                    {
                        label: 'Wkrótce dostępne',
                        href: baseUrl + '/pl/p/wkrotce-dostepne/',
                        id: 'upcoming',
                    },
                    {
                        label: 'O nas',
                        href: '/___arte_tv/sites/pl/corporate/nasza-dzialalnosc/?lang=pl',
                        id: 'about',
                    },
                ],
                
            },
    };
});

export {
  $$default as default,
}
/* No side effect */
