// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util_I18n from "../util/Util_I18n.res.mjs";
import * as Util_Array from "../util/Util_Array.res.mjs";
import * as Type_String from "./Type_String.res.mjs";

function keyToString(key) {
  switch (key) {
    case "Chatbot" :
        return "Chatbot";
    case "Events" :
        return "Events";
    case "EventsFavorite" :
        return "EventsFavorite";
    case "Magazine" :
        return "Magazine";
    case "Password" :
        return "Password";
    
  }
}

function isCurrentKey(key, currentKey) {
  if (key === currentKey) {
    return "Current";
  } else {
    return "Default";
  }
}

function makeHref(locale, subroute) {
  return "/" + Util_I18n.localeToString(locale) + "/profile/" + (
          subroute !== undefined ? subroute : ""
        );
}

var Profile = {
  makeHref: makeHref
};

function makeItems(labels, currentKey, locale) {
  var match = labels.mona.navigation;
  var firstItem = {
    TAG: "Link",
    _0: {
      key: "Events",
      label: match.events,
      href: Type_String.Href.make("/" + Util_I18n.localeToString(locale) + "/profile/events"),
      state: isCurrentKey("Events", currentKey)
    }
  };
  var allItems = [{
      TAG: "Link",
      _0: {
        key: "EventsFavorite",
        label: match.eventsFavorite,
        href: Type_String.Href.make("/" + Util_I18n.localeToString(locale) + "/profile/events-favorite"),
        state: isCurrentKey("EventsFavorite", currentKey)
      }
    }];
  var frOnly = [{
      TAG: "Link",
      _0: {
        key: "Magazine",
        label: match.magazine,
        href: Type_String.Href.make("/arte-magazine"),
        state: isCurrentKey("Magazine", currentKey)
      }
    }];
  var frDeOnly = [{
      TAG: "Link",
      _0: {
        key: "Chatbot",
        label: match.chatbot,
        href: Type_String.Href.make("/___arte_tv/___subd-63686174626f74/" + Util_I18n.localeToString(locale)),
        state: isCurrentKey("Chatbot", currentKey)
      }
    }];
  switch (locale) {
    case "Fr" :
        return Util_Array.NonEmpty.make(allItems.concat(frDeOnly).concat(frOnly), firstItem);
    case "De" :
        return Util_Array.NonEmpty.make(allItems.concat(frDeOnly), firstItem);
    default:
      return Util_Array.NonEmpty.make(allItems, firstItem);
  }
}

var Header = {
  makeItems: makeItems
};

export {
  keyToString ,
  isCurrentKey ,
  Profile ,
  Header ,
}
/* No side effect */
