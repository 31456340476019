// Generated by ReScript, PLEASE EDIT WITH CARE


var $$default = (function (baseUrl) {
    return {
     
            Concert: {
                title: 'ARTE Concert',
                url: baseUrl + '/es/arte-concert/',
                navigation: {
                    links: [
                        {
                            label: 'Explorar por género',
                            href: baseUrl + '/es/arte-concert/los-mas-recientes/',
                            id: 'concert-genres',
                            kind: 'other',
                        },
                    ],
                },
                socialNetworks:{
                    facebook: null,
                    twitter: null,
                    instagram: null,
                    youtube: null,
                    tiktok: null,
                },
                       concertSelectGenre:{
                    title:{label:"Recorrer los géneros", href:baseUrl +"/es/arte-concert/los-mas-recientes/"},
                    items:[
                        {
                            label:"Pop y rock",
                            href:baseUrl +"/es/p/pop-y-rock/"
                        },
                        {
                            label:"Música electrónica",
                            href:baseUrl +"/es/p/musica-electronica/"
                        },
                        {
                            label:"Metal",
                            href:baseUrl +"/es/p/metal/"
                        },
                        {
                            label:"Hiphop",
                            href: baseUrl +"/es/p/hip-hop/"
                        },
                        {
                            label:"Jazz",
                            href:baseUrl +"/es/p/jazz/"
                        },
                        {
                            label:"Clásica",
                            href:baseUrl +"/es/p/clasica/"
                        },
                        {
                            label:"Ópera",
                            href:baseUrl +"/es/p/opera/"
                        },
                        {
                            label:"Música barroca",
                            href:baseUrl +"/es/p/musica-barroca/"
                        },
                        {
                            label:"Artes escénicas",
                            href:baseUrl +"/es/p/artes-escenicas/"
                        },
                        {
                            label:"Músicas del mundo",
                            href:baseUrl +"/es/p/musicas-del-mundo/"
                        },
                       
                    ]
               
            },
               guide:null,
            },
            DesktopNavigation: {
                links: [
                    {
                        label: 'Búsqueda',
                        href: baseUrl + '/es/search/',
                        id: 'search',
                    },
                    {
                        label: 'Quiénes somos',
                        href: '/___arte_tv/sites/es/corporate/que-hacemos/',
                        id: 'about',
                    },
                ],
            },
            MenuNavigation: {
                mobileLinks: [
                    {
                        label: 'Inicio',
                        href: baseUrl + '/es/',
                    },
                      {
                        label: 'Pronto disponible',
                        href: baseUrl + '/es/p/pronto-disponible/',
                        id: 'upcoming',
                    },
                    {
                        label: 'Búsqueda',
                        href: baseUrl + '/es/search/',
                        id: 'search',
                    },
                    {
                        label: 'Quiénes somos',
                        href: '/___arte_tv/sites/es/corporate/que-hacemos/',
                        id: 'about',
                    },
                ],
                categories: [
                    {
                        label: 'Política y sociedad',
                        kind: 'internal',
                        href: baseUrl + '/es/videos/politica-y-sociedad/',
                        code: 'ACT',
                    },
                    {
                        label: 'Cine',
                        kind: 'internal',
                        href: baseUrl + '/es/videos/cine/',
                        code: 'CIN',
                    },
                    {
                        label: 'Series',
                        kind: 'internal',
                        href: baseUrl + '/es/videos/series-y-ficciones/',
                        code: 'SER',
                    },
                    {
                        label: 'Cultura y pop',
                        kind: 'internal',
                        href: baseUrl + '/es/videos/cultura-y-pop/',
                        code: 'CPO',
                    },
                    {
                        label: 'ARTE Concert',
                        kind: 'internal',
                        href: baseUrl + '/es/arte-concert/',
                        code: 'ARS',
                    },
                    {
                        label: 'Ciencias',
                        kind: 'internal',
                        href: baseUrl + '/es/videos/ciencias/',
                        code: 'SCI',
                    },
                    {
                        label: 'Viajes y naturaleza',
                        kind: 'internal',
                        href: baseUrl + '/es/videos/viajes-y-naturaleza/',
                        code: 'DEC',
                    },
                    {
                        label: 'Historia',
                        kind: 'internal',
                        href: baseUrl + '/es/videos/historia/',
                        code: 'HIS',
                    },
                ],
                otherLinks: [
                    {
                        label: 'Programas',
                        href: baseUrl + '/es/videos/emisiones/',
                        code: null,
                        kind: 'internal',
                    },
                    {
                        label: 'Videojuego',
                        href: '/___arte_tv/digitalproductions/es/categories/videojuego/',
                        code: null,
                        kind: 'external',
                    },
                    {
                        label: 'Todos los vídeos',
                        href: baseUrl + '/es/videos/mas-vistos/',
                        deeplink: 'arte://programs/popular',
                        code: null,
                        kind: 'internal',
                    },
                    {
                        label: 'Producciones digitales',
                        href: baseUrl + '/digitalproductions/es/',
                        code: null,
                        kind: 'external',
                    },
                ],
            },
            LogoNavigation: {
                label: 'en español',
                title: 'Arte en español',
                href: baseUrl + '/es/',
                links: [
                    {
                        label: 'ARTE Concert',
                        id: 'arte_concert',
                        href: baseUrl + '/es/arte-concert/',
                    },
                     {
                        label: 'Pronto disponible',
                        href: baseUrl + '/es/p/pronto-disponible/',
                        id: 'upcoming',
                    },
                    {
                        label: 'Quiénes somos',
                        href: '/___arte_tv/sites/es/corporate/que-hacemos/',
                        id: 'about',
                    },
                ],
            },
    };
});

export {
  $$default as default,
}
/* No side effect */
