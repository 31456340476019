// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../Api.res.mjs";
import * as User from "../User.res.mjs";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as User_OIDC from "../User_OIDC.res.mjs";
import * as User_Type from "../User_Type.res.mjs";
import * as Util_I18n from "../../util/Util_I18n.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Binding_Date from "../../binding/Binding_Date.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Core__Result from "@rescript/core/src/Core__Result.res.mjs";
import * as User_Api__Env from "./User_Api__Env.res.mjs";
import * as $$Date from "@artegeie/date";

function error_t_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "error",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.error)
                  ],
                  [
                    "success",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.boolToJson, extra);
                        })(v.success)
                  ]
                ]));
}

function error_t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var error = (function (extra) {
        return Spice.optionFromJson(Spice.stringFromJson, extra);
      })(Belt_Option.getWithDefault(Js_dict.get(v, "error"), null));
  if (error.TAG === "Ok") {
    var success = (function (extra) {
          return Spice.optionFromJson(Spice.boolFromJson, extra);
        })(Belt_Option.getWithDefault(Js_dict.get(v, "success"), null));
    if (success.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                error: error._0,
                success: success._0
              }
            };
    }
    var e = success._0;
    return {
            TAG: "Error",
            _0: {
              path: ".success" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = error._0;
  return {
          TAG: "Error",
          _0: {
            path: ".error" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function reason_encode(v) {
  switch (v) {
    case "EmailNotVerified" :
        return ["EmailNotVerified"];
    case "InvalidEmail" :
        return ["InvalidEmail"];
    case "Other" :
        return ["Other"];
    case "EventInvalid" :
        return ["EventInvalid"];
    case "EventAlreadyParticipated" :
        return ["EventAlreadyParticipated"];
    case "EventParticipationEnded" :
        return ["EventParticipationEnded"];
    
  }
}

function reason_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "EmailNotVerified" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "EmailNotVerified"
                  };
          }
      case "EventAlreadyParticipated" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "EventAlreadyParticipated"
                  };
          }
      case "EventInvalid" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "EventInvalid"
                  };
          }
      case "EventParticipationEnded" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "EventParticipationEnded"
                  };
          }
      case "InvalidEmail" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "InvalidEmail"
                  };
          }
      case "Other" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Other"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function mapError(err) {
  var status = err.kind;
  var exit = 0;
  if (typeof status !== "object") {
    switch (status) {
      case "BadRequest" :
          var match = err.response;
          if (match === undefined) {
            return {
                    TAG: "BadRequest",
                    _0: "Other"
                  };
          }
          var match$1 = match.error;
          var exit$1 = 0;
          var match$2 = match.success;
          if (match$2 !== undefined) {
            if (!match$2) {
              return {
                      TAG: "BadRequest",
                      _0: "InvalidEmail"
                    };
            }
            exit$1 = 3;
          } else {
            exit$1 = 3;
          }
          if (exit$1 === 3) {
            if (match$1 === "email_not_verified ") {
              return {
                      TAG: "BadRequest",
                      _0: "EmailNotVerified"
                    };
            } else {
              return {
                      TAG: "BadRequest",
                      _0: "Other"
                    };
            }
          }
          break;
      case "RequestAborted" :
          return "RequestAborted";
      case "ServerError" :
          return "ServerError";
      case "TooManyRequest" :
          return "TooManyRequest";
      case "InvalidAuthent" :
      case "Unauthorized" :
          exit = 2;
          break;
      default:
        return "Unknown";
    }
  } else {
    switch (status.TAG) {
      case "FailedWithStatus" :
          return {
                  TAG: "FailedWithStatus",
                  _0: status._0
                };
      case "Guard" :
          return "RequestSkipped";
      default:
        return "Unknown";
    }
  }
  if (exit === 2) {
    var match$3 = err.response;
    if (match$3 !== undefined) {
      var match$4 = match$3.error;
      if (match$4 !== undefined) {
        switch (match$4) {
          case "-1" :
              return {
                      TAG: "BadRequest",
                      _0: "EventInvalid"
                    };
          case "-5" :
              return {
                      TAG: "BadRequest",
                      _0: "EventParticipationEnded"
                    };
          case "-6" :
              return {
                      TAG: "BadRequest",
                      _0: "EventAlreadyParticipated"
                    };
          default:
            
        }
      }
      
    }
    
  }
  if (typeof status !== "object") {
    if (status === "InvalidAuthent") {
      return "InvalidAuthent";
    }
    var match$5 = err.response;
    if (match$5 === undefined) {
      return "Unauthorized";
    }
    var match$6 = match$5.success;
    if (match$6 !== undefined && !match$6) {
      return {
              TAG: "BadRequest",
              _0: "InvalidEmail"
            };
    } else {
      return "Unauthorized";
    }
  }
  
}

var $$Error = {
  error_t_encode: error_t_encode,
  error_t_decode: error_t_decode,
  reason_encode: reason_encode,
  reason_decode: reason_decode,
  mapError: mapError
};

var Token = {};

var trailingSlashRegex = new RegExp("/$");

function createUrl(env, domain, path) {
  var path$1 = path.replace(trailingSlashRegex, "");
  var apiEnv = User_Api__Env.envToString(env);
  return "https://api" + apiEnv + "/___arte_tv/api/sso/v3/" + path$1;
}

function defaultHeaders(locale) {
  return [
          [
            "accept-language",
            Util_I18n.localeToString(locale)
          ],
          [
            "access-control-request-headers",
            "content-type"
          ],
          [
            "client",
            "web"
          ],
          [
            "content-type",
            "application/json"
          ]
        ];
}

function MakeRequest(Config) {
  var headersToArray = function (a) {
    return a;
  };
  var getUrl = function (args) {
    var match = Config.endpoint;
    return createUrl(User.Env.get(), Config.domain, match.NAME === "Static" ? match.VAL : match.VAL(args));
  };
  var defaultGetToken = async function () {
    var match = Config.options.tokenKind;
    if (match === undefined) {
      return ;
    }
    if (match !== "User") {
      return User.Api.ssoApiKey();
    }
    var userToken = await User_OIDC.getToken();
    return Core__Option.flatMap(userToken, User_Type.Token.createBearerToken);
  };
  var getToken = Core__Option.getOr(Config.options.overrideGetToken, defaultGetToken);
  var getHeaders = function (locale, param, param$1) {
    return defaultHeaders(locale);
  };
  var options_fetchOptions = {
    getToken: getToken,
    withCredentials: Config.options.withCredentials,
    guard: (async function (overrideBearerToken, param) {
        var token = overrideBearerToken !== undefined ? overrideBearerToken : await getToken();
        var userStatus = Core__Option.getOr(Core__Option.map(Core__Option.map(token, User_Type.Token.make), User_Type.Status.fromToken), "Disconnected");
        var userRestriction = User_Type.getApiAccess(User.get());
        var match = Config.options.userRestriction;
        if (typeof userRestriction === "object" && $$Date.isAfter(userRestriction._0, new Date())) {
          return "Refused";
        }
        if (match === undefined) {
          return "Allowed";
        }
        switch (match) {
          case "Anonymous" :
              switch (userStatus) {
                case "Anonymous" :
                case "Connected" :
                    return "Allowed";
                case "Disconnected" :
                    return "NotEnoughRights";
                
              }
          case "Connected" :
              switch (userStatus) {
                case "Connected" :
                    return "Allowed";
                case "Anonymous" :
                case "Disconnected" :
                    return "NotEnoughRights";
                
              }
          case "Disconnected" :
              return "Allowed";
          
        }
      })
  };
  var options_callbacks = {
    onSuccess: (function (param) {
        User.set(User_Type.changeApiRestriction(User.get(), "Allowed"));
      }),
    onError: (function (error) {
        var tmp = error.kind;
        if (typeof tmp === "object") {
          return ;
        }
        switch (tmp) {
          case "ServerError" :
          case "TooManyRequest" :
              break;
          default:
            return ;
        }
        var dateWithTtl = Binding_Date.addMinutes(new Date(), User.TTL.getSsoRestrictionTTL());
        User.set(User_Type.changeApiRestriction(User.get(), {
                  TAG: "Disallowed",
                  _0: dateWithTtl
                }));
      })
  };
  var options_responseDecoder = Config.decodeApiResponse;
  var options_errorDecoder = Config.errorResponse_decode;
  var options_overrideFetch = Config.options.overrideFetch;
  var options = {
    fetchOptions: options_fetchOptions,
    callbacks: options_callbacks,
    responseDecoder: options_responseDecoder,
    errorDecoder: options_errorDecoder,
    overrideFetch: options_overrideFetch
  };
  var method = Core__Option.getOr(Config.options.method, "Get");
  var call = Api.make({
        getUrl: getUrl,
        method: method,
        getHeaders: getHeaders,
        headersToArray: headersToArray,
        options: options
      });
  var call$1 = async function (param) {
    var tmpResp = await call({
          args: param.args,
          signal: param.signal,
          locale: param.locale,
          overrideBearerToken: Core__Option.map(param.overrideToken, User_Type.Token.toString)
        });
    return Core__Result.mapError(tmpResp, Config.mapError);
  };
  return {
          call: call$1
        };
}

function MakeMutation(Config) {
  var domain = Config.domain;
  var mapError = Config.mapError;
  var endpoint = Config.endpoint;
  var method;
  var tmp = Config.method;
  if (typeof tmp !== "object") {
    switch (tmp) {
      case "Get" :
          method = "Get";
          break;
      case "Post" :
          method = {
            TAG: "Post",
            _0: Config.makeBody
          };
          break;
      case "Put" :
          method = {
            TAG: "Put",
            _0: Config.makeBody
          };
          break;
      case "Delete" :
          method = {
            TAG: "Delete",
            _0: Config.makeBody
          };
          break;
      case "Patch" :
          method = {
            TAG: "Patch",
            _0: Config.makeBody
          };
          break;
      default:
        console.error("Method not supported for mutation");
        method = "Get";
    }
  } else {
    console.error("Method not supported for mutation");
    method = "Get";
  }
  var newrecord = Caml_obj.obj_dup(Config.options);
  newrecord.method = method;
  var headersToArray = function (a) {
    return a;
  };
  var getUrl = function (args) {
    return createUrl(User.Env.get(), domain, endpoint.NAME === "Static" ? endpoint.VAL : endpoint.VAL(args));
  };
  var defaultGetToken = async function () {
    var match = newrecord.tokenKind;
    if (match === undefined) {
      return ;
    }
    if (match !== "User") {
      return User.Api.ssoApiKey();
    }
    var userToken = await User_OIDC.getToken();
    return Core__Option.flatMap(userToken, User_Type.Token.createBearerToken);
  };
  var getToken = Core__Option.getOr(newrecord.overrideGetToken, defaultGetToken);
  var getHeaders = function (locale, param, param$1) {
    return defaultHeaders(locale);
  };
  var options_fetchOptions = {
    getToken: getToken,
    withCredentials: newrecord.withCredentials,
    guard: (async function (overrideBearerToken, param) {
        var token = overrideBearerToken !== undefined ? overrideBearerToken : await getToken();
        var userStatus = Core__Option.getOr(Core__Option.map(Core__Option.map(token, User_Type.Token.make), User_Type.Status.fromToken), "Disconnected");
        var userRestriction = User_Type.getApiAccess(User.get());
        var match = newrecord.userRestriction;
        if (typeof userRestriction === "object" && $$Date.isAfter(userRestriction._0, new Date())) {
          return "Refused";
        }
        if (match === undefined) {
          return "Allowed";
        }
        switch (match) {
          case "Anonymous" :
              switch (userStatus) {
                case "Anonymous" :
                case "Connected" :
                    return "Allowed";
                case "Disconnected" :
                    return "NotEnoughRights";
                
              }
          case "Connected" :
              switch (userStatus) {
                case "Connected" :
                    return "Allowed";
                case "Anonymous" :
                case "Disconnected" :
                    return "NotEnoughRights";
                
              }
          case "Disconnected" :
              return "Allowed";
          
        }
      })
  };
  var options_callbacks = {
    onSuccess: (function (param) {
        User.set(User_Type.changeApiRestriction(User.get(), "Allowed"));
      }),
    onError: (function (error) {
        var tmp = error.kind;
        if (typeof tmp === "object") {
          return ;
        }
        switch (tmp) {
          case "ServerError" :
          case "TooManyRequest" :
              break;
          default:
            return ;
        }
        var dateWithTtl = Binding_Date.addMinutes(new Date(), User.TTL.getSsoRestrictionTTL());
        User.set(User_Type.changeApiRestriction(User.get(), {
                  TAG: "Disallowed",
                  _0: dateWithTtl
                }));
      })
  };
  var options_responseDecoder = Config.decodeApiResponse;
  var options_errorDecoder = Config.errorResponse_decode;
  var options_overrideFetch = newrecord.overrideFetch;
  var options = {
    fetchOptions: options_fetchOptions,
    callbacks: options_callbacks,
    responseDecoder: options_responseDecoder,
    errorDecoder: options_errorDecoder,
    overrideFetch: options_overrideFetch
  };
  var method$1 = Core__Option.getOr(newrecord.method, "Get");
  var call = Api.make({
        getUrl: getUrl,
        method: method$1,
        getHeaders: getHeaders,
        headersToArray: headersToArray,
        options: options
      });
  var call$1 = async function (param) {
    var tmpResp = await call({
          args: param.args,
          signal: param.signal,
          locale: param.locale,
          overrideBearerToken: Core__Option.map(param.overrideToken, User_Type.Token.toString)
        });
    return Core__Result.mapError(tmpResp, mapError);
  };
  return {
          call: call$1
        };
}

export {
  $$Error ,
  Token ,
  trailingSlashRegex ,
  createUrl ,
  defaultHeaders ,
  MakeRequest ,
  MakeMutation ,
}
/* trailingSlashRegex Not a pure module */
