// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../CssJs.res.mjs";
import * as Util_Dom from "../util/Util_Dom.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Particle_Color from "./particle/Particle_Color.res.mjs";
import * as Particle_Spacer from "./particle/Particle_Spacer.res.mjs";
import * as Layout_Constants from "./layout/Layout_Constants.res.mjs";
import * as Particle_Css_font from "./particle/Particle_Css_font.res.mjs";
import * as Particle_Background_Ds from "./particle/Particle_Background_Ds.res.mjs";
import * as Particle_Background_Css from "./particle/Particle_Background_Css.res.mjs";

function injectFonts() {
  [
    CssJs.fontFace("barna", [
          CssJs.url("/___arte_tv/___subd-7374617469632d63646e/static/fonts/barna-web/woff2/Barna-Light.woff2"),
          CssJs.url("/___arte_tv/___subd-7374617469632d63646e/static/fonts/barna-web/woff/Barna-Light.woff")
        ], Css.normal, Css.thin, "swap", undefined, undefined),
    CssJs.fontFace("barna-fallback", [{
            NAME: "localUrl",
            VAL: "Arial"
          }], Css.normal, Css.thin, undefined, CssJs.pct(83), undefined),
    CssJs.fontFace("barna", [
          CssJs.url("/___arte_tv/___subd-7374617469632d63646e/static/fonts/barna-web/woff2/Barna-Regular.woff2"),
          CssJs.url("/___arte_tv/___subd-7374617469632d63646e/static/fonts/barna-web/woff/Barna-Regular.woff")
        ], Css.normal, Css.normal, "swap", undefined, undefined),
    CssJs.fontFace("barna-fallback", [{
            NAME: "localUrl",
            VAL: "Arial"
          }], Css.normal, Css.normal, undefined, CssJs.pct(84), undefined),
    CssJs.fontFace("barna", [
          CssJs.url("/___arte_tv/___subd-7374617469632d63646e/static/fonts/barna-web/woff2/Barna-SemiBold.woff2"),
          CssJs.url("/___arte_tv/___subd-7374617469632d63646e/static/fonts/barna-web/woff/Barna-SemiBold.woff")
        ], Css.normal, Css.semiBold, "swap", undefined, undefined),
    CssJs.fontFace("barna-fallback", [{
            NAME: "localUrl",
            VAL: "Arial"
          }], Css.normal, Css.semiBold, undefined, CssJs.pct(84), undefined),
    CssJs.fontFace("barna", [
          CssJs.url("/___arte_tv/___subd-7374617469632d63646e/static/fonts/barna-web/woff2/Barna-Bold.woff2"),
          CssJs.url("/___arte_tv/___subd-7374617469632d63646e/static/fonts/barna-web/woff/Barna-Bold.woff")
        ], Css.normal, Css.bold, "swap", undefined, undefined),
    CssJs.fontFace("barna-fallback", [{
            NAME: "localUrl",
            VAL: "Arial"
          }], Css.normal, Css.bold, undefined, CssJs.pct(92), undefined)
  ];
}

var selector = [
  "a",
  "abbr",
  "acronym",
  "address",
  "applet",
  "article",
  "aside",
  "audio",
  "b",
  "big",
  "blockquote",
  "body",
  "button",
  "canvas",
  "caption",
  "center",
  "cite",
  "code",
  "dd",
  "del",
  "details",
  "dfn",
  "div",
  "dl",
  "dt",
  "em",
  "embed",
  "fieldset",
  "figcaption",
  "figure",
  "footer",
  "form",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "header",
  "hgroup",
  "i",
  "iframe",
  "ins",
  "kbd",
  "label",
  "legend",
  "li",
  "mark",
  "menu",
  "nav",
  "object",
  "ol",
  "output",
  "p",
  "pre",
  "q",
  "ruby",
  "s",
  "samp",
  "section",
  "small",
  "span",
  "strike",
  "strong",
  "sub",
  "summary",
  "sup",
  "table",
  "tbody",
  "td",
  "tfoot",
  "th",
  "thead",
  "time",
  "tr",
  "tt",
  "u",
  "ul",
  "var",
  "video"
];

var common_1 = [
  CssJs.margin(CssJs.zero),
  CssJs.padding(CssJs.zero),
  CssJs.fontSize(CssJs.pct(100)),
  CssJs.verticalAlign(CssJs.baseline),
  CssJs.unsafe("border", "0")
];

var common = [
  selector,
  common_1
];

var html_0 = [
  "body",
  "html"
];

var html_1 = [CssJs.color(CssJs.$$var(Particle_Color.Var.getVarName("Text")))];

var html = [
  html_0,
  html_1
];

var list_0 = [
  "ul",
  "ol"
];

var list_1 = [CssJs.listStyleType(CssJs.none)];

var list = [
  list_0,
  list_1
];

function injectGlobal(param) {
  CssJs.$$global(param[0].join(","), param[1]);
}

var img_0 = ["img"];

var img_1 = [CssJs.maxWidth(CssJs.pct(100))];

var img = [
  img_0,
  img_1
];

var all_0 = ["*"];

var all_1 = [CssJs.outlineStyle("none")];

var all = [
  all_0,
  all_1
];

var link_0 = [
  "a",
  "a:hover",
  "a:focus"
];

var link_1 = [CssJs.textDecoration("none")];

var link = [
  link_0,
  link_1
];

var button_0 = ["a, button, input, select, textarea"];

var button_1 = [
  CssJs.outline(CssJs.px(2), CssJs.solid, CssJs.transparent),
  CssJs.outlineOffset(CssJs.px(0)),
  CssJs.borderStyle(CssJs.none),
  CssJs.color(CssJs.$$var(Particle_Color.Var.getVarName("Text"))),
  CssJs.fontFamily("inherit_"),
  CssJs.margin("zero")
];

var button = [
  button_0,
  button_1
];

var reset = [
  all,
  html,
  common,
  list,
  link,
  img,
  button
];

function run() {
  reset.forEach(injectGlobal);
}

var Reset = {
  common: common,
  html: html,
  list: list,
  injectGlobal: injectGlobal,
  img: img,
  all: all,
  link: link,
  button: button,
  reset: reset,
  run: run
};

function useTheme(theme) {
  var $$document = Util_Dom.SafeWebApi.$$document;
  if ($$document === undefined) {
    return ;
  }
  var html = Caml_option.valFromOption($$document).querySelector("html");
  var className = Particle_Color.Var.getThemeClassName(theme);
  if (!(html == null)) {
    html.className = className;
    return ;
  }
  
}

function useDarkTheme() {
  useTheme("Dark");
}

function injectTheme(theme) {
  var className = Particle_Color.Var.getThemeClassName(theme);
  var rules = Particle_Color.Css.varColorsToCss(Particle_Color.Var.getThemeVariables(theme));
  CssJs.$$global("html." + className, rules);
}

function initTheme(selectorOpt, theme) {
  var selector = selectorOpt !== undefined ? selectorOpt : ":root";
  var rules = Particle_Color.Css.varColorsToCss(Particle_Color.Var.getThemeVariables(theme));
  CssJs.$$global(selector, rules);
}

function injectSpacing(selectorOpt) {
  var selector = selectorOpt !== undefined ? selectorOpt : ":root";
  CssJs.$$global(selector, [
          Particle_Spacer.varSpacerCss,
          Particle_Css_font.varSizesCss,
          Particle_Css_font.varLineHeightsCss
        ].flat());
}

function make(withFont, theme) {
  if (withFont) {
    injectFonts();
  }
  reset.forEach(injectGlobal);
  CssJs.$$global("html", [
        CssJs.boxSizing(CssJs.borderBox),
        Particle_Css_font.baseSize,
        CssJs.unsafe("scrollBehavior", "smooth")
      ]);
  CssJs.$$global("body", [
        CssJs.unsafe("WebkitFontSmoothing", "antialiased"),
        CssJs.unsafe("MozOsxFontSmoothing", "grayscale")
      ]);
  CssJs.$$global("*, ::after, ::before", [CssJs.boxSizing(CssJs.inherit_)]);
  CssJs.$$global("::placeholder", [Particle_Css_font.baseSize]);
  CssJs.$$global("input[type=\"search\"]::-webkit-search-decoration,::-webkit-search-cancel-button,\n  ::-webkit-search-results-button,::-webkit-search-results-decoration", [CssJs.display("none")]);
  CssJs.$$global("select", [CssJs.unsafe("-webkit-border-radius", "0px")]);
  injectTheme("Dark");
  injectTheme("Light");
  injectSpacing(undefined);
  initTheme(undefined, theme);
}

function replayGlobalStyle(withFont, theme) {
  make(withFont, theme);
  CssJs.$$global("html", Belt_Array.concatMany([
            [
              CssJs.display("flex"),
              CssJs.justifyContent(CssJs.center),
              CssJs.unsafe("scrollBehavior", "smooth")
            ],
            Particle_Background_Css.toCss(Particle_Background_Ds.background)
          ]));
  CssJs.$$global("body", [
        CssJs.width(CssJs.pct(100)),
        CssJs.maxWidth(Layout_Constants.maxWidthMainContent),
        CssJs.margin4(Css.px(0), Css.auto, Css.px(0), Css.auto),
        CssJs.display("flex"),
        CssJs.alignContent("center"),
        CssJs.overflowX("hidden"),
        CssJs.flexDirection("column")
      ]);
}

function make$1(withFont, param) {
  if (withFont) {
    injectFonts();
  }
  injectGlobal(button);
}

var User = {
  make: make$1
};

export {
  injectFonts ,
  Reset ,
  useTheme ,
  useDarkTheme ,
  injectTheme ,
  initTheme ,
  injectSpacing ,
  make ,
  replayGlobalStyle ,
  User ,
}
/* common Not a pure module */
